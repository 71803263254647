import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";

import { CAMPAIGN_TYPES } from "Common";
import _ from "i18n";
import {
  onSuccess,
  onError,
  findKey,
  SVG_TRASH_ICON,
  SVG_EDIT_PENCIL_ICON,
  SVG_DUPLICATE_USER_ICON,
  SVG_STOP_ICON,
  SVG_PAUSE_ICON,
  SVG_PLAY_ICON,
  SVG_TREND_ICON,
} from "utils";
import { updateCampaignStatus } from "thunks";
import { setCurrentDndTheme } from "../../../actions/actions/theme";
import { setCurrentEmailCampaignData } from "../../../actions/actions/currentCampaign";
import {
  getCampaignStatus,
  getDate,
  SVG_CALENDAR_ICON,
  SVG_USER_PLUS_ICON,
  SVG_SHARE_ICON,
  SVG_FLAG_ICON,
} from "../../../services/utils";
import moment from "moment";

class ActionsCell extends Component {
  handleDeleteClick(campaignId) {
    this.props.deleteAction();
    this.props.selectedCampaignId(campaignId);

    return false;
  }

  handleCampaignAction(id, status) {
    const { dispatch } = this.props;
    if (status === "HALT") {
      this.props.stopAction();
      this.props.onStatus(id, status);
    } else {
      dispatch(updateCampaignStatus({ id, status })).then(
        (res) => onSuccess(res),
        (err) => onError(err),
      );
    }
  }

  handleEditCampaign = () => {
    const { item, history, isUAVersion } = this.props;
    const { id, type, newsLetter } = item;
    let typeQuery = findKey(CAMPAIGN_TYPES, type);

    if (type === "DND_TEMPLATE" && newsLetter === 1) {
      typeQuery = "newsletter";
    } else if (type === "PLAIN_TEXT") {
      typeQuery = "text";
    }

    if (isUAVersion) {
      if (type === "PLAIN_TEXT") {
        this.props.handleSetCurrentPage("RICH_TEXT_PAGE", id);
      } else {
        this.props.handleSetCurrentPage("SEND_PAGE", id);
      }
    } else {
      history.push(`/campaign/${id}?type=${typeQuery}`);
    }
  };

  handleCompleteAction = (campaign) => {
    this.props.dispatch(setCurrentEmailCampaignData(campaign));
    this.props.completeCampaign();

    return false;
  };

  handleApprovalAction = (campaign) => {
    this.props.dispatch(setCurrentEmailCampaignData(campaign));
    this.props.campaignApproval();

    return false;
  };

  handleShareAction = (campaign) => {
    this.props.dispatch(setCurrentEmailCampaignData(campaign));
    this.props.shareCampaign();

    return false;
  };

  handleChangeScheduledAtDateAction = (campaign) => {
    this.props.dispatch(setCurrentEmailCampaignData(campaign));
    this.props.changeScheduledAtDate();

    return false;
  };

  render() {
    const {
      onDuplicateAction,
      item,
      colorClass,
      history,
      language,
      isUAVersion,
    } = this.props;
    const {
      id,
      status,
      approvalsCount,
      totalApprovers,
      scheduledDeliveryAt,
      isArchived,
    } = item;
    let date = {};
    if (scheduledDeliveryAt) {
      var sd = new Date(scheduledDeliveryAt);
      if (sd.toString() === "Invalid Date") {
        sd = new Date(scheduledDeliveryAt.replace(/-/g, "/"));
      }
      const tmp = {
        date:
          sd.getFullYear() +
          "-" +
          (sd.getMonth() + 1) +
          "-" +
          sd.getDate() +
          " " +
          sd.getHours() +
          ":" +
          sd.getMinutes() +
          ":" +
          sd.getSeconds(),
        timezone: "UTC",
      };
      date = { date: tmp };
    }

    let momentScheduledDeliveryAt = getDate(date.date, language, false, true);
    let isScheduledAtExpired = momentScheduledDeliveryAt < moment();
    return (
      <div className="medium-2">
        <div
          className="list-item__status"
          style={{ position: "relative", top: "20px" }}
        >
          <i className={`bg-${colorClass}`} />
          {_(getCampaignStatus(item, isScheduledAtExpired))}
          {["APPROVAL REQUEST", "approved"].indexOf(getCampaignStatus(item)) !==
            -1 && (
            <span className="list-item__status-count">
              {" "}
              {approvalsCount + "/" + totalApprovers}{" "}
            </span>
          )}
        </div>
        <div
          className="list-item__actions"
          style={{ position: "relative", top: "15px" }}
        >
          {status === "SENT" && !isUAVersion && !isArchived && (
            <div
              data-for={"restart" + id}
              data-tip={_("share")}
              onClick={() => this.handleShareAction(item)}
            >
              {SVG_SHARE_ICON}
              <ReactTooltip id={"restart" + id} type="dark" place="bottom" />
            </div>
          )}
          {["DRAFT", "TEST", "PAUSE"].indexOf(status) !== -1 ? (
            <div
              data-for={"edit" + id}
              data-tip={_("edit")}
              onClick={this.handleEditCampaign}
            >
              {SVG_EDIT_PENCIL_ICON}
              <ReactTooltip id={"edit" + id} type="dark" place="bottom" />
            </div>
          ) : (
            <div
              data-for={"report" + id}
              data-tip={_("report")}
              onClick={() =>
                isUAVersion
                  ? this.props.handleSetCurrentPage("STATS_PAGE", id)
                  : history.push(`/stats/${id}`)
              }
            >
              {SVG_TREND_ICON}
              <ReactTooltip id={"report" + id} type="dark" place="bottom" />
            </div>
          )}
          {["PAUSE"].indexOf(status) > -1 && (
            <div
              data-for={"report" + id}
              data-tip={_("report")}
              onClick={() => history.push(`/stats/${id}`)}
            >
              {SVG_TREND_ICON}
              <ReactTooltip id={"report" + id} type="dark" place="bottom" />
            </div>
          )}
          {["CREATED", "PAUSE"].indexOf(status) > -1 &&
            scheduledDeliveryAt &&
            !isScheduledAtExpired &&
            !isArchived && (
              <div
                data-for={"changeDate" + id}
                data-tip={_("change sending date")}
                onClick={() => this.handleChangeScheduledAtDateAction(item)}
              >
                {SVG_CALENDAR_ICON}
                <ReactTooltip
                  id={"changeDate" + id}
                  type="dark"
                  place="bottom"
                />
              </div>
            )}
          {["RECIPIENT_LIST_CREATED", "CREATED"].indexOf(status) > -1 &&
            !isArchived && (
              <div
                data-for={"pause" + id}
                data-tip={_("pause")}
                onClick={() => this.handleCampaignAction(id, "PAUSE")}
              >
                {SVG_PAUSE_ICON}
                <ReactTooltip id={"pause" + id} type="dark" place="bottom" />
              </div>
            )}
          {status === "PAUSE" && (
            <div
              data-for={"resume" + id}
              data-tip={_("resume")}
              onClick={() => this.handleCampaignAction(id, "UNPAUSE")}
            >
              {SVG_PLAY_ICON}
              <ReactTooltip id={"resume" + id} type="dark" place="bottom" />
            </div>
          )}
          {status === "PAUSE" && (
            <div
              data-for={"stop" + id}
              data-tip={_("stop definitely")}
              onClick={() => this.handleCampaignAction(id, "HALT")}
            >
              {SVG_STOP_ICON}
              <ReactTooltip id={"stop" + id} type="dark" place="bottom" />
            </div>
          )}
          {status === "SENT" && !isArchived && (
            <div
              data-for={"restart" + id}
              data-tip={_("complete")}
              onClick={() => this.handleCompleteAction(item)}
            >
              {SVG_USER_PLUS_ICON}
              <ReactTooltip id={"restart" + id} type="dark" place="bottom" />
            </div>
          )}
          {status === "SENT" && totalApprovers > 0 && !isArchived && (
            <div
              data-for={"approval" + id}
              data-tip={_("approval")}
              onClick={() => this.handleApprovalAction(item)}
            >
              {SVG_FLAG_ICON}
              <ReactTooltip id={"approval" + id} type="dark" place="bottom" />
            </div>
          )}

          {!isArchived && (
            <div
              data-for={"duplicate" + id}
              data-tip={_("duplicate")}
              onClick={onDuplicateAction}
            >
              {SVG_DUPLICATE_USER_ICON}
              <ReactTooltip id={"duplicate" + id} type="dark" place="bottom" />
            </div>
          )}
          {!isArchived && (
            <div
              className={"delete-action"}
              data-for={"delete" + id}
              data-tip={_("delete")}
              onClick={() => this.handleDeleteClick(id)}
            >
              {SVG_TRASH_ICON}
              <ReactTooltip id={"delete" + id} type="dark" place="bottom" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(connect(), withRouter)(ActionsCell);
